import React from "react";
import { Route, Routes} from "react-router-dom";
import withRouter from "./Components/Hooks/withRouter"
import { Home } from "./Components/Home/Home";
import { Portfolio } from "./Components/Portfolio/Portfolio";
import { Contact } from "./Components/Contact/Contact";
import { About } from "./Components/About/About";
import { SocialIcons } from "./Components/SocialIcons/SocialIcons";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const AnimatedRoutes = withRouter(({ location }) => (
  <TransitionGroup>
    <CSSTransition
      key={location.key}
      timeout={{
        enter: 400,
        exit: 400,
      }}
      classNames="page"
      unmountOnExit
    >
      <Routes location={location}>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </CSSTransition>
  </TransitionGroup>
));

function AppRoutes() {
  return (
    <div className="s_c">
      <AnimatedRoutes />
      <SocialIcons />
    </div>
  );
}

export default AppRoutes;
