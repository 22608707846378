const logoText = "MARKDUCNGUYEN";
const meta = {
    title: "Mark Duc",
    description: "I’m Mark Duc data  _ Full stack devloper,currently working in Berlin",
};

const introData = {
    title: "I’m Mark Duc",
    animated: {
        first: "I design",
        second: "I photograph",
        third: "I videograph",
        fourth: "I do animation",
        fifth: ""
    },
    description: "A product by Minh Nguyen & Mark Duc Nguyen",
    your_img_url: "../../Asset/home.jpg",
};

const dataAbout = {
    title: "abit about my self",
    aboutme: "I am an Independent Production Artist and Graphic Designer with years of experience in build art direction and appealing advertisement experiences.",
    aboutme2: "I specialize in visual design ",
    aboutme3: "with a strong focus on ideation. I also love to illustrate animation elements to make each experience unique"

};
const workTimeline = [
    {
        jobtitle: "Graphic Designer Intern",
        where: "Greysteel",
        date: "Jun 2023 - current",
    },{
        jobtitle: "Graphic Designer Intern",
        where: "Better Business Bureau",
        date: "Jun 2022 - Dec 2022",
    },
    {
        jobtitle: "Motion Graphic Designer Intern",
        where: "South Creative Vietnam",
        date: "Jan 2022 - Mar 2022",
    },
    {
        jobtitle: "Graphic Design Intern",
        where: "Tay Cộng Tay - HandsOn",
        date: "Jul 2021 - Sep 2021",
    },
    {
        jobtitle: "Website/UI and Motion Design Intern",
        where: "Clay website design and building agency",
        date: "Aug 2020 - Dec 2020",
    },
    {
        jobtitle: "Production Intern",
        where: "Sống Foundation",
        date: "Jul 2019 - Aug 2019",
    }
];

const skills = [{
        name: "Adobe Creative Suite",
        value: 90,
    },
    {
        name: "Photography",
        value: 85,
    },
    {
        name: "Videography",
        value: 80,
    },
    {
        name: "Motion Design",
        value: 60,
    },
    {
        name: "Web Design",
        value: 69,
    },
    {
        name: "Art Direction",
        value: 85,
    },
];

const services = [{
        title: "Async Cut (Ho Chi Minh city)",
        description: "South Creative Vietnam",
        where: "Miele Honey"
    }
];

const dataPortfolio = [
    {
        img: "https://i.pinimg.com/736x/49/0e/8c/490e8c80e4f7063119f467776e7771ff.jpg",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/urbanista-headphone-advertising-campaign",
        title: 'Urbanista Headphone Advertising Campaign'
    },
    {
        img: "https://i.pinimg.com/736x/03/48/cb/0348cb8be04a97f2493f498dcc0079b7.jpg",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/hot-wheels-x-daniel-arsham-eroded-porsche-930",
        title: 'Hot Wheels x Daniel Arsham Eroded Porsche 930'
    },
    {
        img: "https://i.pinimg.com/originals/00/aa/00/00aa00e52485a19c0d09eec35b67193e.png",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/better-business-bereau-portfolio",
        title: 'Better Business Bereau Portfolio'
    },
    {
        img: "https://i.pinimg.com/originals/6a/c3/e7/6ac3e790f2df970d68d10a0b51873cb2.png",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/sai-gon-poster",
        title: 'Sài Gòn Poster'
    },
    {
        img: "https://i.pinimg.com/originals/08/64/25/0864258a7446e49e5a777bf816fbdfd8.jpg",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/kho-qua-music-video-official",
        title: 'Khổ Qua | Music Video Official'
    },
    {
        
        img: "https://i.pinimg.com/originals/6c/cc/4b/6ccc4bcb6f2c7ffcbd2b136b50eb75f9.jpg",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/cincinnati-skyscraper-photography",
        title: 'Cincinnati Skyscraper - Photography'
    },
    {
        
        img: "https://i.pinimg.com/originals/30/01/99/3001990c78aea300a3c983e4c41790ff.jpg",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/async-fashion-brand",
        title: 'Async - Fashion Brand'
    },
    {
        img: "https://i.pinimg.com/originals/8b/62/6a/8b626a736af8324d6d70f7a41b0bc4fd.jpg",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/rog-phone-5-ultimate",
        title: 'ROG Phone 5 Ultimate'
    },
    {
        img: "https://i.pinimg.com/originals/9c/d2/e9/9cd2e9515aa3589e777bd3aa0e21a547.jpg",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/lovish-luxury-chocolate-brand",
        title: 'LOVISH - Luxury chocolate brand'
    },

    {
        img: "https://i.pinimg.com/originals/8b/8a/27/8b8a2793d350eef62872a39ead2348d9.jpg",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/hau-giang-people",
        title: 'Hậu Giang people'
    },
    {
        img: "https://i.pinimg.com/originals/fd/f2/ab/fdf2abf0cc29a9eba37965a3919cd5f7.jpg",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/painting",
        title: 'Painting'
    },
    {
        img: "https://i.pinimg.com/originals/f9/46/5a/f9465a808a7ec67c95ff9fa15df4986d.jpg",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/photography",
        title: 'Photography'
    },

    {
        img: "https://i.pinimg.com/originals/f9/46/5a/f9465a808a7ec67c95ff9fa15df4986d.jpg",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/photography",
        title: 'Project 1'
    },
    {
        img: "https://i.pinimg.com/originals/f9/46/5a/f9465a808a7ec67c95ff9fa15df4986d.jpg",
        desctiption: "",
        link: "https://mark123duc.myportfolio.com/photography",
        title: 'Project 1'
    },

];

const contactConfig = {
    YOUR_EMAIL: "mark123duc@gmail.com",
    YOUR_FONE: "(513)-837-0357",
    description: "",
    // https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_abg9ajv",
    YOUR_TEMPLATE_ID: "template_xpp7win",
    YOUR_USER_ID: "a8YvaLuHg6dOVbWIE",
};

const socialProfiles = {
    insta: "https://www.instagram.com/markducnguyen/",
    facebook: "https://www.facebook.com/markducnguyen",
    linkedin: "https://www.linkedin.com/in/markducnguyen/",
    behance: "https://www.behance.net/markducnguyen",
};
export {
    meta,
    dataAbout,
    dataPortfolio,
    workTimeline,
    skills,
    services,
    introData,
    contactConfig,
    socialProfiles,
    logoText,
};