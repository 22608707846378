import React from "react";
import "./Portfolio.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { dataPortfolio, meta } from "../../ContentOptions";
import Popup from "reactjs-popup";
import portfolioImageDark from "../../Asset/portfolio_dark.png";
import portfolioImageLight from "../../Asset/portfolio_light.png";

export const Portfolio = () => {
  // {dataPortfolioPortfolio.map((dataPortfolio, i) => {
  //   console.log({itemList})
  //   return (
  //     <Col key={i} className="po_item">
  //       <img src={dataPortfolio.img} alt="" />
  //       <div className="content">
  //         <p>{dataPortfolio.desctiption}</p>
  //         <a href={dataPortfolio.link}>view project</a>
  //       </div>
  //     </Col>
  //   );
  // })}

  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Portfolio | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col className="po_header" lg="8">
          <img
              src={portfolioImageDark}
              alt="Portfolio"
              className="dark-image display-3 mb-4"
              style={{
                width: "100%",
                maxWidth: "260px",
                height: "auto"
              }}
            /> 
             <img
              src={portfolioImageLight}
              alt="Portfolio"
              className="light-image display-3 mb-4"
              style={{
                width: "100%",
                maxWidth: "260px",
                height: "auto"
              }}
            /> 
            <hr className="t_border my-1 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="mb-5 po_items_ho">
          <Col key={0} className="po_item">
            <img src={dataPortfolio[0].img} alt=""/>
              <div className="content">
                <h2>{dataPortfolio[0].title}</h2>
                <Popup trigger={<button className="button"></button>} modal >
                <iframe src={dataPortfolio[0].link} width = "100%" height = "106%" title="Embedded Web" />
                </Popup>
              </div>
          </Col>
          <Col key={1} className="po_item">
            <img src={dataPortfolio[1].img} alt="" /> 
            <div className="content">
              <h2>{dataPortfolio[1].title}</h2>
              <Popup trigger={<button className="button"></button>} modal>
              <iframe src={dataPortfolio[1].link} width = "100%" height = "106%" title="Embedded Web" />
              </Popup>
            </div>
          </Col>
        </Row>
        <Row className="mb-5 po_items_ho">
          <Col key={2} className="po_item">
            <img src={dataPortfolio[2].img} alt="" />
            <div className="content">
              <h2>{dataPortfolio[2].title}</h2>
              <Popup trigger={<button className="button"></button>} modal>
              <iframe src={dataPortfolio[2].link} width = "100%" height = "106%" title="Embedded Web" />
              </Popup>
            </div>
          </Col>
          <Col key={3} className="po_item">
            <img src={dataPortfolio[3].img} alt="" />
            <div className="content">
              <h2>{dataPortfolio[3].title}</h2>
              <Popup trigger={<button className="button"></button>} modal>
              <iframe src={dataPortfolio[3].link} width = "100%" height = "106%" title="Embedded Web" />
              </Popup>
            </div>
          </Col>
        </Row>
        <Row className="mb-5 po_items_ho">
          <Col key={4} className="po_item">
            <img src={dataPortfolio[4].img} alt="" />
            <div className="content">
              <h2>{dataPortfolio[4].title}</h2>
              <Popup trigger={<button className="button"></button>} modal>
              <iframe src={dataPortfolio[4].link} width = "100%" height = "106%" title="Embedded Web" />
              </Popup>
            </div>
          </Col>
          <Col key={5} className="po_item">
            <img src={dataPortfolio[5].img} alt="" />
            <div className="content">
              <h2>{dataPortfolio[5].title}</h2>
              <Popup trigger={<button className="button"></button>} modal>
              <iframe src={dataPortfolio[5].link} width = "100%" height = "106%" title="Embedded Web" />
              </Popup>
            </div>
          </Col>
        </Row>
        <Row className="mb-5 po_items_ho">
          <Col key={6} className="po_item">
            <img src={dataPortfolio[6].img} alt="" />
            <div className="content">
              <h2>{dataPortfolio[6].title}</h2>
              <Popup trigger={<button className="button"></button>} modal>
              <iframe src={dataPortfolio[6].link} width = "100%" height = "106%" title="Embedded Web" />
              </Popup>
            </div>
          </Col>
          <Col key={7} className="po_item">
            <img src={dataPortfolio[7].img} alt="" />
            <div className="content">
              <h2>{dataPortfolio[7].title}</h2>
              <Popup trigger={<button className="button"></button>} modal>
              <iframe src={dataPortfolio[7].link} width = "100%" height = "106%" title="Embedded Web" />
              </Popup>
            </div>
          </Col>
        </Row>
        <Row className="mb-5 po_items_ho">
          <Col key={8} className="po_item">
            <img src={dataPortfolio[8].img} alt="" />
            <div className="content">
              <h2>{dataPortfolio[8].title}</h2>
              <Popup trigger={<button className="button"></button>} modal>
              <iframe src={dataPortfolio[8].link} width = "100%" height = "106%" title="Embedded Web" />
              </Popup>
            </div>
          </Col>
          <Col key={9} className="po_item">
            <img src={dataPortfolio[9].img} alt="" />
            <div className="content">
              <h2>{dataPortfolio[9].title}</h2>
              <Popup trigger={<button className="button"></button>} modal>
              <iframe src={dataPortfolio[9].link} width = "100%" height = "106%" title="Embedded Web" />
              </Popup>
            </div>
          </Col>
        </Row>
        <Row className="mb-5 po_items_ho">
          <Col key={10} className="po_item">
            <img src={dataPortfolio[10].img} alt="" />
            <div className="content">
              <h2>{dataPortfolio[10].title}</h2>
              <Popup trigger={<button className="button"></button>} modal>
              <iframe src={dataPortfolio[10].link} width = "100%" height = "106%" title="Embedded Web" />
              </Popup>
            </div>
          </Col>
          <Col key={11} className="po_item">
            <img src={dataPortfolio[11].img} alt="" />
            <div className="content">
              <h2>{dataPortfolio[11].title}</h2>
              <Popup trigger={<button className="button"></button>} modal>
              <iframe src={dataPortfolio[11].link} width = "100%" height = "106%" title="Embedded Web" />
              </Popup>
            </div>
          </Col>
          {/* <Col key={8} className="po_item_empty">
            
          </Col> */}
        </Row>
      </Container>
    </HelmetProvider>
  );
};