import './App.css';
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
//import withRouter from "./Hooks/withRouter";
import AppRoutes from "./routes";
import Header from "./Components/Header/Header";
import AnimatedCursor  from "./Components/Hooks/AnimatedCursor";
import { ThemeProvider } from './Components/ThemeToggle/ThemeContext';

import "./App.css";

function ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

function App() {

  return (
      <Router basename={process.env.PUBLIC_URL}>
        <div className="cursor__dot">
          <AnimatedCursor
            innerSize={15}
            outerSize={15}
            color="255, 255, 255"
            outerAlpha={0.4}
            innerScale={0.7}
            outerScale={5}
          />
        </div>
        <ScrollToTop>
          <div style={{minHeight: '1vh'}}>
            <Header  />
          </div>
          <div style={{minHeight: '100vh'}}>
            <AppRoutes />
          </div>
        </ScrollToTop>
      </Router>
  );
}

export default App;
