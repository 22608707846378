import React from "react";
import "./SocialIcons.css";
import {
  FaInstagram,
  FaFacebookF,
  FaLinkedin,
  FaBehance ,
} from "react-icons/fa";
import { socialProfiles } from "../../ContentOptions";

export const SocialIcons = (params) => {
  return (
    <div className="stick_follow_icon">
      <ul>
        {socialProfiles.behance && (
          <li>
            <a href={socialProfiles.behance}>
              <FaBehance />
            </a>
          </li>
        )}
        {socialProfiles.linkedin && (
          <li>
            <a href={socialProfiles.linkedin}>
              <FaLinkedin />
            </a>
          </li>
        )}
        {socialProfiles.insta && (
          <li>
            <a href={socialProfiles.insta}>
              <  FaInstagram />
            </a>
          </li>
        )}
        {socialProfiles.facebook && (
          <li>
            <a href={socialProfiles.facebook}>
              <FaFacebookF />
            </a>
          </li>
        )}

      </ul>
      <p>Follow Me</p>
    </div>
  );
};