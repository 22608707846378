import React from "react";
import "./About.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import {
  dataAbout,
  meta,
  workTimeline,
  skills,
  services,
} from "../../ContentOptions";
// import { useTheme } from '../ThemeToggle/ThemeContext';
import aboutImageDark from "../../Asset/aboutme_dark.png";
import aboutImageLight from "../../Asset/aboutme_light.png";
import hommeImage from "../../Asset/home.jpeg"
export const About = () => {

  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> About | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="sec_head mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <img
              src={aboutImageDark}
              alt="About Me"
              className="dark-image display-3 mb-4"
              style={{
                width: "100%",
                maxWidth: "260px",
                height: "auto"
              }}
            />    
            <img
              src={aboutImageLight}
              alt="About Me"
              className="light-image display-3 mb-4"
              style={{
                width: "100%",
                maxWidth: "260px",
                height: "auto"
              }}

            />  
            <hr className="t_border my-1 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col className="about_im">
          <img src={hommeImage} alt="" className="img-fluid" style={{width:"90%"}}/>
          </Col>
          <Col  className="d-flex align-items-center py-4">
            <div>
              <p>{dataAbout.aboutme}</p>
              <br></br>
              <p>{dataAbout.aboutme2}{dataAbout.aboutme3}</p>
            </div>
          </Col>
        </Row>
        <Row className=" sec_sp">
          <Col >
            <h3 className="color_sec py-2">Work Timeline</h3>
            <div className="vertical_line"></div>
          </Col>
          <Col >
            <table
             className="table caption-top "
             style={{
                margin_top:"100px"
             }}
            >
              <tbody>
                {workTimeline.map((data, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{data.jobtitle}</th>
                      <td>{data.where}</td>
                      <td>{data.date}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col >
            <h3 className="color_sec">Skills</h3>
            <div className="vertical_line"></div>
          </Col>
          <Col >
            <table className="table caption-top">
                <tbody>
                  {skills.map((data, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{data.name}</th>
                        {/* <td>{data.where}</td>
                        <td>{data.date}</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            {/* {skills.map((data, i) => {
              return (
                <div key={i}>
                  <h3 className="progress-title">{data.name}</h3>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${data.value}%`,
                      }}
                    >
                     
                    </div>
                  </div>
                </div>
              );
            })} */}
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col >
            <h3 className="color_sec py-2">Clients</h3>
            <div className="vertical_line3"></div>
          </Col>
          <Col >
            {services.map((data, i) => {
              return (
                <div className="service_ py-4" key={i}>
                  <h5 className="service__title">{data.title}</h5>
                  <p className="service_desc">{data.description} <br></br> {data.where}</p>
                  
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};
